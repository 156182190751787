<template>
  <div class="spline-container">
    <iframe
      id="splineFrame"
      src="https://my.spline.design/metaversevisual-34d4f55141cc67782e593a10b1dc1b83/"
      frameborder="0"
      width="300px"
      height="300px"
    ></iframe>
  </div>
</template>

<script>
export default {
    name: 'SplineAnimation'
}
</script>

<style>
.spline-container {
  width: 300px;
  height: 300px;
  position: relative;
}

.spline-container::after {
  content: "";
  width: 50px;
  height: 50px;
  background: #f4f4f4;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.spline-container > iframe {
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>